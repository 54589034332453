<template>
  <div class="fill-height">
    <Description @next="toNext" v-if="step === 0" />
    <Information v-if="step === 1" />
  </div>
</template>
<script>
import Description from "@/components/mobile/quote/description";
import Information from "@/components/mobile/quote/information";
export default {
  name: "MobileQuote",
  components: {
    Description,
    Information,
  },
  data: () => ({
    step: 0,
    //
  }),
  mounted() {},
  methods: {
    toNext() {
      this.step = this.step + 1;
    },
  },
};
</script>
