export default class {
    constructor(data) {
        this.type = data.type;
        this.county = data.county;
        this.district = data.district;
        this.road = data.road;
        this.status = data.status;
        this.service = data.service;
        this.services = data.services;
    }
    exportMessage() {
        let result = {
            type: "flex",
            altText: "估價單",
            contents: {
                type: "bubble",
                body: {
                    type: "box",
                    layout: "vertical",
                    contents: [
                        {
                            type: "text",
                            text: "估價單",
                            weight: "bold",
                            color: "#1DB446",
                            size: "sm",
                        },
                        {
                            type: "text",
                            text: this.type,
                            weight: "bold",
                            size: "lg",
                            margin: "md",
                        },
                        {
                            type: "text",
                            text: `${this.county} ${this.district} ${this.road}`,
                            size: "sm",
                            color: "#aaaaaa",
                            wrap: true,
                        },
                        {
                            type: "separator",
                            margin: "md",
                        },
                        {
                            type: "box",
                            layout: "vertical",
                            margin: "md",
                            spacing: "sm",
                            contents: [
                                {
                                    type: "box",
                                    layout: "vertical",
                                    contents: [],
                                },
                                {
                                    type: "separator",
                                    margin: "md",
                                },
                                {
                                    type: "text",
                                    text: this.service,
                                    weight: "bold",
                                    size: "md",
                                    margin: "lg",
                                },
                                {
                                    type: "box",
                                    layout: "vertical",
                                    margin: "md",
                                    contents: [],
                                },
                                {
                                    type: "separator",
                                    margin: "xxl",
                                },
                                {
                                    type: "text",
                                    text: "感謝您的詢問，會盡快回覆您報價！",
                                    size: "md",
                                    color: "#444444",
                                    margin: "lg",
                                    weight: "bold",
                                    wrap: true,
                                },
                            ],
                        },
                    ],
                },
                styles: {
                    footer: {
                        separator: true,
                    },
                },
            },
        };
        this.status.forEach((item) => {
            result.contents.body.contents[4].contents[0].contents.push({
                type: "text",
                text: item ? item : "--",
                size: "sm",
                color: "#555555",
                flex: 0,
                margin: "sm",
            });
        });

        this.services.forEach((item) => {
            let temp = [];
            temp.push({
                type: "text",
                text: item.title,
                size: "xs",
                color: "#aaaaaa",
            });
            temp.push({
                type: "text",
                text: item.value ? item.value : "--",
                size: "sm",
                color: "#111111",
                wrap: true,
            });

            result.contents.body.contents[4].contents[3].contents.push({
                type: "box",
                layout: "vertical",
                margin: "md",
                contents: [...temp],
            });
        });

        return result;
    }

    exportText() {
        let result = "類型:" + this.type + "\n";
        result += "地點狀況:" + this.status.join("、") + "\n";
        result += `地址:${this.county} ${this.district} ${this.road} \n`;
        result += "項目:" + this.service + "\n\n";
        this.services.forEach(item => {
            result += item.title + item.value + "\n";
        })
        return result;

    }
}