<template>
  <v-container style="height: 100%">
    <div v-if="loading === false" class="d-flex flex-column justify-space-between fill-height">
      <div>
        <v-row>
          <v-col>
            <h2 class="font-weight-black text-center">基本資料</h2>
          </v-col>
        </v-row>
        <v-row class="mx-5 my-5">
          <v-col cols="12">
            <h4>類型</h4>
            <v-item-group active-class="primary" v-model="selected.type">
              <v-item class="mr-2 mt-2" v-slot="{ active, toggle }" v-for="item in getAvailablelist(datalist.types)" :key="item.sort" :value="item.content">
                <v-btn :input-value="active" depressed rounded @click="toggle"> {{ item.content }} </v-btn>
              </v-item>
            </v-item-group>
          </v-col>

          <v-col cols="12">
            <h4>施工地點狀況</h4>
            <div class="d-flex">
              <v-checkbox :label="item.content" class="my-0 mr-3" v-for="item in getAvailablelist(datalist.locationStatuses)" :key="item.sort" v-model="selected.locationStatuses" :value="item.content"> </v-checkbox>
            </div>

            <h4 class="mt-4">地址</h4>
            <v-row>
              <v-col> <v-select label="縣市" :items="countylist" v-model="selected.county"></v-select></v-col>
              <v-col> <v-select label="區域" :items="districtlist" v-model="selected.district"></v-select></v-col>
            </v-row>

            <v-row>
              <v-col> <v-select label="街道" :items="streetlist" v-model="selected.street"></v-select></v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <h4>項目</h4>
            <v-item-group class="mt-1" active-class="primary" v-model="selected.service" @change="selectService">
              <v-item class="mt-2 mr-2" v-slot="{ active, toggle }" v-for="item in getAvailablelist(datalist.services)" :key="item.sort" :value="item.content">
                <v-btn :input-value="active" depressed rounded @click="toggle"> {{ item.content }} </v-btn>
              </v-item>
            </v-item-group>
          </v-col>

          <v-col cols="12">
            <div v-for="(item, index) in servicelist" :key="item.sort">
              <h4 class="mt-4">{{ item.title }}</h4>
              <v-radio-group class="mt-0" v-if="item.type === 0" v-model="selected.servicelist[index]">
                <v-radio v-for="option in item.options" :key="option.sort" :label="option.content" :value="option.content"> </v-radio>
              </v-radio-group>
              <div class="form-check form-check-inline" v-else>
                <v-checkbox class="my-0" v-for="option in item.options" :key="option.sort" :label="option.content" :value="option.content" v-model="selected.servicelist[index]"> </v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-footer color="white" class="pb-5 mx-5">
        <v-btn rounded color="primary" dark block @click="submit()"> 估價 </v-btn>
      </v-footer>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular :width="14" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.show = false"> 關閉 </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import liff from "@line/liff";
import lineMessage from "@/commons/templates/quoteMessage";

export default {
  name: "MobileQuoteInformation",
  data: () => ({
    snackbar: {
      show: false,
      message: "",
    },

    loading: true,

    datalist: {
      types: [],
      locationStatuses: [],
      counties: [],
      services: [],
    },

    selected: {
      type: "",
      locationStatuses: [],
      county: "",
      district: "",
      street: "",
      service: "",
      servicelist: [],
    },
    //
  }),
  mounted() {
    this.init();
  },
  computed: {
    servicelist() {
      if (this.selected.service && this.datalist.services.length > 0) {
        let result = this.datalist.services.find((p) => p.content === this.selected.service);
        return result.items;
      } else {
        return [];
      }
    },
    countylist() {
      let list = this.getAvailablelist(this.datalist.counties);
      return list.map((p) => p.content);
    },
    districtlist() {
      let county = this.datalist.counties.find((p) => p.content === this.selected.county);
      return county ? this.getAvailablelist(county.districts).map((p) => p.content) : [];
    },
    streetlist() {
      let county = this.datalist.counties.find((p) => p.content === this.selected.county);
      if (!county) return [];
      let district = county.districts.find((p) => p.content === this.selected.district);
      return district ? this.getAvailablelist(district.streets).map((p) => p.content) : [];
    },
  },
  methods: {
    validate() {
      if (!this.selected.type) {
        this.showSnackbar("請選擇類型");
        return false;
      }

      if (!this.selected.county) {
        this.showSnackbar("請選擇縣市");
        return false;
      }

      if (!this.selected.district) {
        this.showSnackbar("請選擇地區");
        return false;
      }

      if (!this.selected.street) {
        this.showSnackbar("請選擇街道");
        return false;
      }

      if (!this.selected.service) {
        this.showSnackbar("請選擇要估價之服務");
        return false;
      }

      return true;
    },

    selectService(val) {
      let service = this.datalist.services.find((p) => p.content === val);
      this.selected.servicelist = [];
      service.items.forEach((item) => {
        if (item.type === 0) this.selected.servicelist.push("");
        if (item.type === 1) this.selected.servicelist.push([]);
      });
    },
    submit() {
      if (this.validate() === false) return;
      this.loading = true;
      let data = {
        type: this.selected.type,
        county: this.selected.county,
        district: this.selected.district,
        road: this.selected.street,
        service: this.selected.service,
        status: this.selected.locationStatuses,
        services: this.servicelist.map((item, index) => {
          if (item.type === 0) return { title: item.title, value: this.selected.servicelist[index] };
          if (item.type === 1) return { title: item.title, value: this.selected.servicelist[index].join("、") };
        }),
      };
      let content = new lineMessage(data);
      let service = {
        type: this.selected.type,
        locationStatuses: this.selected.locationStatuses,
        service: this.selected.service,
        content: this.servicelist.map((item, index) => ({
          title: item.title,
          type: item.type,
          value: this.selected.servicelist[index],
        })),
      };
      liff.init({ liffId: "1656147464-XQQN1qZr" }).then(() => {
        liff.getProfile().then((profile) => {
          this.$http
            .post("/quote/record", {
              userId: profile.userId,
              displayName: profile.displayName,
              county: this.selected.county,
              district: this.selected.district,
              street: this.selected.street,
              service: JSON.stringify(service),
            })
            .then((response) => {
              liff.sendMessages([content.exportMessage()]).then(() => {
                liff.closeWindow();
              });
              this.loading = false;
            });
        });
      });
    },
    async init() {
      await this.getServices();
      await this.getCounties();
      await this.getStatus();
      this.loading = false;
    },
    async getServices() {
      await this.$http.get("/service").then((response) => {
        if (response.data.success) {
          this.datalist.services = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    async getCounties() {
      await this.$http.get("/service/county").then((response) => {
        if (response.data.success) {
          this.datalist.counties = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    async getStatus() {
      await this.$http.get("/service/status").then((response) => {
        if (response.data.success) {
          this.datalist.types = response.data.result.types;
          this.datalist.locationStatuses = response.data.result.locationStatuses;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    showSnackbar(val) {
      this.snackbar.message = val;
      this.snackbar.show = true;
    },
    getAvailablelist(array) {
      return array.filter((p) => p.enable);
    },
  },
};
</script>

<style>
.v-input .v-messages {
  min-height: 0;
}

.v-input__control .v-input__slot {
  margin-bottom: 0;
}
</style>